"use client";

import * as gtag from "@/utils/gtm";

import { getLocalStorage, setLocalStorage } from "@/utils/localStorageUtils";
import { FC, HTMLAttributes, useEffect, useState } from "react";

import { Consent } from "@/utils/gtm";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { ItemsGlobal } from "../../robot/backendSchemas";
import styles from "./ConsentBanner.module.scss";

type ConsentBannerProps = HTMLAttributes<HTMLDivElement> & {
  text: ItemsGlobal["consent_text"];
  datapolicy_url: ItemsGlobal["datapolicy_url"];
  label_accept: ItemsGlobal["button_label_yes"];
  label_decline: ItemsGlobal["button_label_no"];
};

const ConsentBanner: FC<ConsentBannerProps> = ({
  className,
  text,
  datapolicy_url,
  label_accept,
  label_decline,
  ...other
}): JSX.Element => {
  const [cookieConsent, setCookieConsent] = useState<Consent>();
  const [displayBanner, setDisplayBanner] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  // Overwrite only given keys
  const _setCookieConsent = (obj: Partial<Consent>) => {
    // Update state while providing defaults
    setCookieConsent((prev) => {
      return {
        ad_user_data: obj.ad_user_data ?? prev?.ad_user_data ?? "denied", // Provide a default if undefined
        ad_storage: obj.ad_storage ?? prev?.ad_storage ?? "denied",
        ad_personalization:
          obj.ad_personalization ?? prev?.ad_personalization ?? "denied",
        analytics_storage:
          obj.analytics_storage ?? prev?.analytics_storage ?? "denied",
        personalization_storage:
          obj.personalization_storage ??
          prev?.personalization_storage ??
          "denied",
        functionality_storage:
          obj.functionality_storage ?? prev?.functionality_storage ?? "denied",
        security_storage:
          obj.security_storage ?? prev?.security_storage ?? "denied",
        noodles_consent:
          obj.noodles_consent ?? prev?.noodles_consent ?? "denied",
      };
    });
  };

  const router = useRouter();

  useEffect(() => {
    console.log("Consent Banner initializing");

    const displayBannerStored = getLocalStorage(
      "display_consent_banner",
      null
    ) as boolean | null;
    const storedCookieConsent = getLocalStorage(
      "cookie_consent",
      null
    ) as Consent | null;

    // This will display the banner to new users and those who haven't made a decision yet
    if (displayBannerStored === null || storedCookieConsent === null) {
      setDisplayBanner(true);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }

    if (storedCookieConsent !== null) {
      _setCookieConsent(storedCookieConsent);
    } else {
      console.log("Reset consent banner", storedCookieConsent);
      // Example of handling analytics consent
      _setCookieConsent({
        ad_user_data: "denied",
        ad_storage: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        personalization_storage: "denied",
        functionality_storage: "denied",
        security_storage: "denied",
        noodles_consent: "denied",
      });
    }
  }, []);

  useEffect(() => {
    if (cookieConsent === null || cookieConsent === undefined) return;

    setLocalStorage("cookie_consent", cookieConsent);
    gtag.consent(cookieConsent);
  }, [cookieConsent]);

  const handleConsent = (value: boolean) => {
    setLoaded(false);

    if (value === true) {
      _setCookieConsent({
        analytics_storage: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        ad_user_data: "granted",
        noodles_consent: "granted",
      });
    } else if (value === false) {
      _setCookieConsent({
        analytics_storage: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        noodles_consent: "denied",
      });
    }

    setTimeout(() => {
      // Remove the banner from the DOM after the transition is complete
      setDisplayBanner(false);
      setLocalStorage("display_consent_banner", false);
      router.refresh();
    }, 500); // This should match the transition duration in the CSS Module
  };

  return (
    <div
      className={clsx(
        styles.container,
        className,
        {
          [styles.loaded]: loaded,
        },
        { [styles.hidden]: !displayBanner }
      )}
      style={{
        transform: loaded ? `translateY(0%)` : `translateY(100%)`,
      }}
      {...other}
    >
      {text && (
        <>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: text.replace(
                /\[policy\-link\]/g,
                `<a href="${datapolicy_url}" target="_blank">Privacy Policy</a>` ||
                  ""
              ),
            }}
          ></div>
          <div className={styles.buttonContainer}>
            <button
              className={clsx(styles.cta)}
              onClick={() => {
                handleConsent(true);
              }}
            >
              {label_accept || "Accept"}
            </button>
            <button
              className={clsx(styles.secondary)}
              onClick={() => {
                handleConsent(false);
              }}
            >
              {label_decline || "Decline"}
            </button>
          </div>
        </>
      )}
      <script
        dangerouslySetInnerHTML={{
          __html: `<!-- ${JSON.stringify(cookieConsent)} -->`,
        }}
      ></script>
    </div>
  );
};

export default ConsentBanner;
