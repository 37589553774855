import "client-only";

export const getLocalStorage = (key: string, defaultValue: any) => {
    const stickyValue = localStorage.getItem(key);

    // console.log({ stickyValue });

    return stickyValue !== null && stickyValue !== 'undefined' ? JSON.parse(stickyValue) : defaultValue;
}

export const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const listLocalStorage = () => {
    return JSON.stringify({ ...localStorage });
}