import { useEffect, useRef } from "react";

import { usePathname } from "next/navigation";

type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[]
    gtag: any
}

declare const window: WindowWithDataLayer

export type Consent = {
    ad_user_data: "denied" | "granted";
    ad_storage: "denied" | "granted";
    ad_personalization: "denied" | "granted";
    analytics_storage: "denied" | "granted";
    personalization_storage: "denied" | "granted";
    functionality_storage: "denied" | "granted";
    security_storage: "denied" | "granted";
    noodles_consent: "denied" | "granted";
};

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || "";

export const pageview = (url: string) => {
    if (typeof window.dataLayer !== "undefined") {
        window.gtag('page_view', GTM_ID, {
            page_location: url,
        });
        if (window.location.toString().includes('localhost')) {
            console.log({
                event: "pageview",
                page: url,
            })
        }
    } else {
        if (window.location.toString().includes('localhost')) {
            console.log({
                event: "pageview",
                page: url,
            })
        }
    }
}

export const consent = (consent: Consent, initial: boolean = false) => {
    if (typeof window.dataLayer !== "undefined") {
        if (initial) {
            window.gtag("consent", "default", consent);
        } else {
            window.gtag("consent", "update", consent);
        }
    } else {
        console.error('Tried to update consent but found no datalayer');
    }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (
    action: Gtag.EventNames,
    { event_category, event_label, value }: Gtag.EventParams,
) => {
    if (typeof window.dataLayer !== "undefined") {
        window.gtag('event', action, {
            event_category,
            event_label,
            value,
        });
    } else {
        console.log({
            event: action,
            event_category,
            event_label,
            value,
        })
    }
};

export const useGtag = () => {
    const pathname = usePathname(); // Get current route

    // Save pathname on component mount into a REF
    const savedPathNameRef = useRef(pathname);

    useEffect(() => {
        // Uncomment below if you dont want local tag manager trigger
        // if (process.env.NODE_ENV !== 'development') return;

        const handleRouteChange = (url: URL) => {
            pageview(url.toString());
        };

        if (savedPathNameRef.current !== pathname) {
            handleRouteChange(new URL(pathname, window.location.origin));
            // Update REF
            savedPathNameRef.current = pathname;
        }
    }, [pathname,]);
};