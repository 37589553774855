"use client";

import * as gtag from "@/utils/gtm";

import Script from "next/script";

export default function GoogleTagManager() {
  gtag.useGtag();

  return (
    <>
      {/* // Uncomment below if you dont want local tag manager trigger
      {process.env.NODE_ENV === "development" && (
        <> */}
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    const consent = localStorage.getItem('cookie_consent')
                    if(consent === null){
                        console.log('Setting default consent as nothing was found in localStorage', consent);
                        gtag('consent', 'default', {
                        'ad_user_data': 'denied',
                        'ad_storage': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied',
                        'personalization_storage': 'denied',
                        'functionality_storage': 'denied',
                        'security_storage': 'denied',
                        'noodles_consent': 'denied'
                        })
                    } else {
                        console.log('Setting default consent from localStorage', consent);
                        gtag('consent', 'default', JSON.parse(consent))
                        if(JSON.parse(consent).analytics_storage === 'granted'){
                          gtag('js', new Date());
                          gtag('page_view', '${gtag.GTM_ID}', {
                              page_location: window.location.pathname,
                          });
                        }
                    }
                    `,
        }}
      />
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${gtag.GTM_ID}');
                `,
        }}
      />
    </>
  );
}
